import React, { Component, Suspense } from 'react';
import withSizes from 'react-sizes';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import Loader from '../Components/Loader/Loader';
import store from '../Redux/store';

import './App.css';

const DesktopApp = React.lazy(() => import('./DesktopApp.js'));
const MobileApp = React.lazy(() => import('./MobileApp.js'));

class App extends Component {
  render() {
    const { isMobile } = this.props;

    return (
      <Provider store={store}>
        {isMobile ? (
          <Suspense fallback={Loader()}>
            <MobileApp />
          </Suspense>
        ) : (
          <Suspense fallback={Loader()}>
            <DesktopApp />
          </Suspense>
        )}
      </Provider>
    );
  }
}

App.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 1000,
});

export default withSizes(mapSizesToProps)(App);
