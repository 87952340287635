import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import 'antd/dist/antd.css';
import { hot } from "react-hot-loader/root";

const render = (Component) =>
  ReactDOM.render(<Component />, document.getElementById("root"));

render(hot(App));
