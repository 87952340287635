const defaultState = {
  inppChildren: {
    personalInfo: {
      name: '',
      surname: '',
      dateOfBirth: '',
      adress: '',
      parent: '',
      phoneNumber: '',
      email: '',
      permission: false,
      diagnoses: '',
      prescriptionMeds: '',
      interventions: '',
    },
    partOne: {
      q1: '',
      q2: false,
      q3: false,
      q4: false,
      q4text: '',
      q4a: false,
      q4b: false,
      q4c: false,
      q4d: false,
      q5: false,
      q6: false,
      q6text: '',
      q7: false,
      q7text: '',
      q8: false,
      q9: false,
      q9a: false,
      q9b: '',
      q10: false,
      q11: false,
      q12: false,
      q13: false,
      q14: false,
      q15: false,
      q16: false,
      q17: false,
      q18: false,
      q18text: '',
      q19: false,
      q19a: false,
      q20: false,
      q21: false,
      q22: false,
      q22select: 'none',
      q23: false,
      q24: false,
      q25: false,
      q26: false,
      q26a: false,
      q27: false,
      q28: false,
      q29: false,
      q30: false,
      q31: false,
      q32: false,
      q33: false,
      q34: false,
      q35: '',
    },
    partTwo: {
      q1: [],
      q2: [],
      q2text: '',
      q3: [],
      q4: [],
      q4text: '',
      q5: '',
      q5a: '',
      q5b: '',
      q5btext: '',
    },
    partThree: {
      q1: false,
      q2: false,
      q3: false,
      q4: false,
      q5: [],
      q6: [],
      q7: [],
      q8: [],
    },
    finalQuestions: {
      q1: [],
      q1text: '',
    },
  },

  inppAdult: {
    personalInfo: {
      name: '',
      surname: '',
      dateOfBirth: '',
      adress: '',
      parent: '',
      phoneNumber: '',
      email: '',
      permission: false,
      diagnoses: '',
      prescriptionMeds: '',
      medicalCondition: '',
      signs: '',
      psychicalHealth: '',
    },
    commonQuestions: {
      q1: false,
      q2: false,
      q3: false,
      q3text: '',
      q3a: false,
      q3b: false,
      q3c: false,
      q3d: false,
      q3e: '',
      q4: false,
      q4text: '',
      q5: false,
      q5text: '',
      q6: false,
      q6text: '',
      q7: false,
      q7text: '',
      q8: false,
      q9: false,
      q9text: '',
      q10: false,
      q11: false,
      q12: false,
      q13: false,
      q14: false,
      q14text: '',
      q15: false,
      q16: false,
      q17: false,
      q17text: '',
      q18: false,
      q19: false,
      q20: false,
      q21: false,
      q22: false,
      q22select: [],
      q23: false,
      q24: false,
      q24text: '',
      q24text2: '',
    },
    schoolAttendance: {
      q1: false,
      q2: false,
      q3: false,
      q4: false,
      q5: false,
      q6: false,
      q7: false,
      q8: false,
      q9: false,
      q10: false,
      q11: false,
    },
    adultLife: {
      q1: false,
      q2: false,
      q2text: '',
      q3: '',
      q4: false,
      q4text: '',
      q5: '',
      q6: '',
      q7: '',
      q8: '',
      q9: '',
      q10: '',
      q11: false,
      q12: false,
      q13: '',
      q14: false,
      q15: false,
      q16: false,
      q17: false,
      q18: false,
      q19: false,
      q20: '',
    },
    finalQuestions: {
      q1: [],
      q1text: '',
    },
  },

  questShortVs: {
    personalInfos: {
      childsName: '',
      dateOfBirthChild: '',
      adress: '',
      parentsName: '',
      phoneNumber: '',
      email: '',
      permission: false,
    },
    questions: {
      q1: false,
      q2: false,
      q3: false,
      q4: false,
      q5: false,
      q6: false,
      q7: false,
      q8: false,
      q9: false,
      q10: false,
      q11: false,
      q12: false,
      q13: false,
      q14: false,
      q15: false,
      q16: false,
      q17: false,
      q18: false,
      q19: false,
      q20: false,
      q21: false,
      q22: false,
      q23: false,
      q24: false,
      q25: false,
      q26: false,
    },
    usefullInfo: {
      otherInfo: '',
    },
  },
};

export default function formsReducer(state = defaultState, action) {
  switch (action.type) {
    case 'CHANGE_INPPCHILDREN_PERSONALINFO_FORM_DATA': {
      return {
        ...state,
        inppChildren: {
          ...state.inppChildren,
          personalInfo: {
            ...state.inppChildren.personalInfo,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPCHILDREN_PARTONE_FORM_DATA': {
      return {
        ...state,
        inppChildren: {
          ...state.inppChildren,
          partOne: {
            ...state.inppChildren.partOne,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPCHILDREN_PARTTWO_FORM_DATA': {
      return {
        ...state,
        inppChildren: {
          ...state.inppChildren,
          partTwo: {
            ...state.inppChildren.partTwo,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPCHILDREN_PARTTHREE_FORM_DATA': {
      return {
        ...state,
        inppChildren: {
          ...state.inppChildren,
          partThree: {
            ...state.inppChildren.partThree,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPCHILDREN_FINALQUESTIONS_FORM_DATA': {
      return {
        ...state,
        inppChildren: {
          ...state.inppChildren,
          finalQuestions: {
            ...state.inppChildren.finalQuestions,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPADULT_PERSONALINFO_FORM_DATA': {
      return {
        ...state,
        inppAdult: {
          ...state.inppAdult,
          personalInfo: {
            ...state.inppAdult.personalInfo,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPADULT_COMMONQUESTION_FORM_DATA': {
      return {
        ...state,
        inppAdult: {
          ...state.inppAdult,
          commonQuestions: {
            ...state.inppAdult.commonQuestions,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPADULT_SCHOOLATTENDANCE_FORM_DATA': {
      return {
        ...state,
        inppAdult: {
          ...state.inppAdult,
          schoolAttendance: {
            ...state.inppAdult.schoolAttendance,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPADULT_ADULTLIFE_FORM_DATA': {
      return {
        ...state,
        inppAdult: {
          ...state.inppAdult,
          adultLife: {
            ...state.inppAdult.adultLife,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPADULT_FINALQUESTIONS_FORM_DATA': {
      return {
        ...state,
        inppAdult: {
          ...state.inppAdult,
          finalQuestions: {
            ...state.inppAdult.finalQuestions,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPCHILDSHORT_PERSONALINFOS_FORM_DATA': {
      return {
        ...state,
        questShortVs: {
          ...state.questShortVs,
          personalInfos: {
            ...state.questShortVs.personalInfos,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPCHILDSHORT_QUESTIONS_DATA': {
      return {
        ...state,
        questShortVs: {
          ...state.questShortVs,
          questions: {
            ...state.questShortVs.questions,
            ...action.changedValues,
          },
        },
      };
    }
    case 'CHANGE_INPPCHILDSHORT_USEFULLINFO_FORM_DATA': {
      return {
        ...state,
        questShortVs: {
          ...state.questShortVs,
          usefullInfo: {
            ...state.questShortVs.usefullInfo,
            ...action.changedValues,
          },
        },
      };
    }
    default:
      return state;
  }
}
